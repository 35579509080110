import { showToaster } from 'app/App.components/Toaster/Toaster.actions'
import { ERROR } from 'app/App.components/Toaster/Toaster.constants'
import axios from 'axios'
import { GetCommentsWithPublicUsersInputs, GetCommentsWithPublicUsersOutputs } from 'shared/comment/GetCommentsWithPublicUsers'

export const GET_COMMENTS_REQUEST = 'GET_COMMENTS_REQUEST'
export const GET_COMMENTS_RESULT = 'GET_COMMENTS_RESULT'
export const GET_COMMENTS_ERROR = 'GET_COMMENTS_ERROR'

export const getComments =
  ({ targetId, page }: GetCommentsWithPublicUsersInputs) =>
  async (dispatch: any) => {
    dispatch({
      type: GET_COMMENTS_REQUEST,
      page,
    })

    try {
      const getCommentsWithPublicUsersOutputs: GetCommentsWithPublicUsersOutputs = (
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/comment/get-comments`, { targetId, page })
      ).data

      dispatch({
        type: GET_COMMENTS_RESULT,
        commentsWithPublicUsers: getCommentsWithPublicUsersOutputs.commentsWithPublicUsers,
        page,
      })
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: GET_COMMENTS_ERROR,
      })
    }
  }
