import { combineReducers } from 'redux'

import { auth, AuthState } from './auth'
import { comments, CommentsState } from './comments'
import { loading, LoadingState } from './loading'
import { popup, PopupState } from './popup'
import { progressBar, ProgressBarState } from './progressBar'
import { toaster, ToasterState } from './toaster'

export const reducers = combineReducers({
  auth,
  loading,
  progressBar,
  toaster,
  popup,
  comments,
})

export interface State {
  auth: AuthState
  loading: LoadingState
  progressBar: ProgressBarState
  toaster: ToasterState
  popup: PopupState
  comments: CommentsState
}
