import { ObjectId } from 'mongodb'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import VisibilitySensor from 'react-visibility-sensor'
import { CommentWithPublicUser } from 'shared/comment/CommentWithPublicUser'

import { CommentReply } from '../Comment/Comment.style'
import { NewComment } from '../NewComment/NewComment.controller'
import { CommentsStyled } from './Comments.style'
import { Comment } from '../Comment/Comment.controller'

type CommentsViewProps = {
  loading: boolean
  allowNewComment: boolean
  targetId?: ObjectId
  commentsWithPublicUsers: Record<string, CommentWithPublicUser>
  hasMore: boolean
  getMoreCallback: (isVisible: boolean) => void
}

export const CommentsView = ({
  loading,
  allowNewComment,
  targetId,
  commentsWithPublicUsers,
  hasMore,
  getMoreCallback,
}: CommentsViewProps) => {
  const renderReplies = (targetId: ObjectId | undefined, depth: number) => {
    if (depth < 10) {
      return (
        <CommentReply>
          {Object.values(commentsWithPublicUsers)
            .filter((commentWithPublicUser) => commentWithPublicUser.comment.targetId === targetId)
            .map((commentWithPublicUser) => (
              <div key={commentWithPublicUser.comment?._id as unknown as string}>
                <Comment commentWithPublicUser={commentWithPublicUser} depth={depth} />
                {renderReplies(commentWithPublicUser.comment._id, depth + 1)}
              </div>
            ))}
        </CommentReply>
      )
    }
    return <div />
  }

  return (
    <CommentsStyled>
      {allowNewComment && <NewComment targetId={targetId} />}
      {Object.values(commentsWithPublicUsers)
        .filter((commentWithPublicUser) => commentWithPublicUser.comment.targetId === targetId)
        .map((commentWithPublicUser) => (
          <div key={commentWithPublicUser.comment?._id as unknown as string}>
            <Comment commentWithPublicUser={commentWithPublicUser} />
            {renderReplies(commentWithPublicUser.comment._id, 1)}
          </div>
        ))}
      {hasMore && (
        <VisibilitySensor onChange={(isVisible: boolean) => getMoreCallback(isVisible)}>
          <div>Loading more...</div>
        </VisibilitySensor>
      )}
    </CommentsStyled>
  )
}

CommentsView.propTypes = {
  loading: PropTypes.bool,
  targetId: PropTypes.string,
  allowNewComment: PropTypes.bool.isRequired,
  commentsWithPublicUsers: PropTypes.object.isRequired,
  hasMore: PropTypes.bool.isRequired,
  getMoreCallback: PropTypes.func.isRequired,
}

CommentsView.defaultProps = {
  loading: false,
}
