import styled from 'styled-components/macro'
import { FullPage } from 'styles'

export const HomeStyled = styled(FullPage)`
  > h1 {
    margin-bottom: 10px;
  }

  pre {
    margin-bottom: 40px;
  }

  a {
    text-decoration: underline;
  }
`
