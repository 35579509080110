import styled from 'styled-components/macro'
import { CardPage } from 'styles'

export const SignUpStyled = styled(CardPage)``

export const SignUpWrapper = styled.div`
  width: 360px;
`

export const SignUpSeparator = styled.div`
  height: 10px;
`

export const SignUpTitle = styled.div`
  margin-bottom: 20px;
`

export const SignUpForm = styled.div`
  input {
    margin-bottom: 10px;
  }
`

export const SignUpPasswordStrengthText = styled.div`
  font-size: 13px;
  line-height: 6px;

  > div {
    float: right;
  }
`

export const SignUpFooter = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: auto 150px;
  grid-gap: 10px;
  line-height: 40px;
  margin-top: 20px;

  > div {
    line-height: 50px;
  }
`
