import * as PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

type LoginMetaProps = {
  meta?: string
}

export const LoginMeta = ({ meta }: LoginMetaProps) => {
  const title = `Login`
  const description = `Log into your Boilerplate account`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  )
}

LoginMeta.propTypes = {
  meta: PropTypes.string,
}

LoginMeta.defaultProps = {
  meta: '',
}
