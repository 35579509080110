import { showToaster } from 'app/App.components/Toaster/Toaster.actions'
import { ERROR } from 'app/App.components/Toaster/Toaster.constants'
import axios from 'axios'
import { State } from 'reducers'
import { NewCommentInputs, NewCommentOutputs } from 'shared/comment/NewComment'

export const NEW_COMMENT_REQUEST = 'NEW_COMMENT_REQUEST'
export const NEW_COMMENT_RESULT = 'NEW_COMMENT_RESULT'
export const NEW_COMMENT_ERROR = 'NEW_COMMENT_ERROR'

export const newComment =
  ({ content, targetId }: NewCommentInputs, doneId: number) =>
  async (dispatch: any, getState: any) => {
    const state: State = getState()

    dispatch({
      type: NEW_COMMENT_REQUEST,
    })

    try {
      const newCommentWithPublicUser: NewCommentOutputs = (
        await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/comment/new-comment`,
          { content, targetId },
          { headers: { Authorization: `Bearer ${state.auth.jwt}` } },
        )
      ).data

      dispatch({
        type: NEW_COMMENT_RESULT,
        doneId,
        commentWithPublicUser: newCommentWithPublicUser.commentWithPublicUser,
      })
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: NEW_COMMENT_ERROR,
      })
    }
  }
