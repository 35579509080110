import { EmptyPage } from 'pages/EmptyPage/EmptyPage.controller'
import { User } from 'pages/User/User.controller'
import { Home } from 'pages/Home/Home.controller'
import { Login } from 'pages/Login/Login.controller'
import { SignUp } from 'pages/SignUp/SignUp.controller'
import { Provider } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { Footer } from './App.components/Footer/Footer.view'
import { Menu } from './App.components/Menu/Menu.controller'
import { Toaster } from './App.components/Toaster/Toaster.controller'
import { configureStore } from './App.store'
import { AppContainer } from './App.style'

export const store = configureStore({})

export const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AppContainer>
          <Menu />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/empty-page" element={<EmptyPage />} />
            <Route path="/user" element={<User />} />
          </Routes>
        </AppContainer>
        <Footer />
        <Toaster />
      </BrowserRouter>
    </Provider>
  )
}
