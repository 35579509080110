import * as PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

type SignUpMetaProps = {
  meta?: string
}

export const SignUpMeta = ({ meta }: SignUpMetaProps) => {
  const title = `SignUp`
  const description = `Log into your Boilerplate account`

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
    </Helmet>
  )
}

SignUpMeta.propTypes = {
  meta: PropTypes.string,
}

SignUpMeta.defaultProps = {
  meta: '',
}
