import * as PropTypes from 'prop-types'
import { useState } from 'react'

import { NewComment } from '../NewComment/NewComment.controller'
import { CommentReply } from './Comment.style'
import { CommentView } from './Comment.view'
import { CommentWithPublicUser } from 'shared/comment/CommentWithPublicUser'

type CommentProps = {
  commentWithPublicUser: CommentWithPublicUser
  depth?: number
}

export const Comment = ({ commentWithPublicUser, depth = 0 }: CommentProps) => {
  const [replying, setReplying] = useState(false)
  const [expanded, setExpanded] = useState(true)

  const replyCallback = () => {
    setReplying(true)
  }

  const expandCallback = (expandState: boolean) => {
    setExpanded(expandState)
  }

  return (
    <>
      <CommentView
        commentWithPublicUser={commentWithPublicUser}
        expanded={expanded}
        replyCallback={replyCallback}
        expandCallback={expandCallback}
        depth={depth}
      />
      {replying && (
        <CommentReply>
          <NewComment targetId={commentWithPublicUser.comment._id} />
        </CommentReply>
      )}
    </>
  )
}

Comment.propTypes = {
  commentWithPublicUser: PropTypes.object.isRequired,
  depth: PropTypes.number,
}

Comment.defaultProps = {
  depth: 0,
}
