import { logout } from 'pages/Login/Login.actions'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { State } from 'reducers'
import { MenuView } from './Menu.view'

export const Menu = () => {
  const [showing, setShowing] = useState(false)
  const user = useSelector((state: State) => state.auth.user)
  const dispatch = useDispatch()

  const logoutCallback = () => {
    dispatch(logout())
  }

  return <MenuView showing={showing} setShowing={setShowing} user={user} logoutCallback={logoutCallback} />
}
