import * as PropTypes from 'prop-types'
import { useCallback } from 'react'

// prettier-ignore
import { CommentAction, CommentActions, CommentExpand, CommentStyled, CommentText } from './Comment.style'
import { CommentWithPublicUser } from 'shared/comment/CommentWithPublicUser'

type CommentViewProps = {
  commentWithPublicUser: CommentWithPublicUser
  expanded: boolean
  replyCallback: () => void
  expandCallback: (expandState: boolean) => void
  depth: number
}

export const CommentView = ({ commentWithPublicUser, expanded, replyCallback, expandCallback, depth }: CommentViewProps) => {
  const { comment, publicUser } = commentWithPublicUser

  const callBackHeightRef = useCallback(
    (domNode) => {
      if (domNode && domNode.getBoundingClientRect().height > 400) {
        expandCallback(false)
      }
    },
    [expandCallback],
  )
  return (
    <CommentStyled>
      <CommentText expanded={expanded} ref={callBackHeightRef}>
        {comment.content}
      </CommentText>
      {!expanded && <CommentExpand onClick={() => expandCallback(true)}>Show More ...</CommentExpand>}
      <CommentActions>
        <div>{publicUser?.username}</div>
        {depth < 9 && <CommentAction onClick={() => replyCallback()}>Reply</CommentAction>}
      </CommentActions>
    </CommentStyled>
  )
}

CommentView.propTypes = {
  commentWithPublicUser: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  replyCallback: PropTypes.func.isRequired,
  expandCallback: PropTypes.func.isRequired,
  depth: PropTypes.number.isRequired,
}

CommentView.defaultProps = {
  expanded: false,
}
