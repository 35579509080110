import { showToaster } from 'app/App.components/Toaster/Toaster.actions'
import { ERROR, SUCCESS } from 'app/App.components/Toaster/Toaster.constants'
import axios from 'axios'
import { SignUpInputs, SignUpOutputs } from 'shared/user/SignUp'

export const SIGN_UP_REQUEST = 'SIGN_UP_REQUEST'
export const SIGN_UP_RESULT = 'SIGN_UP_RESULT'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'

export type SignUp = {
  navigate: any
  username: string
  hashedPassword: string
}

export const signUp =
  ({ navigate, username, hashedPassword }: SignUp) =>
  async (dispatch: any) => {
    dispatch({
      type: SIGN_UP_REQUEST,
    })

    try {
      const signUpInputs: SignUpInputs = {
        username,
        hashedPassword,
      }

      const signUpOutputs: SignUpOutputs = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/sign-up`, signUpInputs)).data
      if (!signUpOutputs.jwt) throw new Error('SignUp Error')

      dispatch(showToaster(SUCCESS, 'Sign Up success', 'Welcome to the boilerplate'))

      dispatch({
        type: SIGN_UP_RESULT,
        jwt: signUpOutputs.jwt,
        publicUser: signUpOutputs.publicUser,
      })

      navigate('/')
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: SIGN_UP_ERROR,
      })
    }
  }
