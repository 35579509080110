import { ObjectId } from 'mongodb'
import * as PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { State } from 'reducers'

import { newComment } from './NewComment.actions'
import { NewCommentView } from './NewComment.view'

type NewCommentProps = {
  targetId?: ObjectId
  alreadyExpanded?: boolean
}

export const NewComment = ({ targetId, alreadyExpanded = false }: NewCommentProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [expanded, setExpanded] = useState(alreadyExpanded)
  const loading = useSelector((state: State) => state.loading)
  const user = useSelector((state: State) => state.auth.user)
  const doneId = useRef(Date.now())
  const newCommentsDoneIds = useSelector((state: State) => state.comments.newCommentsDoneIds)

  const newCommentCallback = (comment: string | undefined) => {
    dispatch(
      newComment(
        {
          content: comment === '' ? undefined : comment,
          targetId,
        },
        doneId.current,
      ),
    )
  }

  const expandCallback = () => {
    if (!user) navigate('/login')
    setExpanded(true)
  }

  return (
    <>
      {newCommentsDoneIds.indexOf(doneId.current) === -1 && (
        <NewCommentView loading={loading} newCommentCallback={newCommentCallback} expanded={expanded} expandCallback={expandCallback} />
      )}
    </>
  )
}

NewComment.propTypes = {
  targetId: PropTypes.string,
  alreadyExpanded: PropTypes.bool,
}

NewComment.defaultProps = {
  targetId: undefined,
  alreadyExpanded: false,
}
