import { showToaster } from 'app/App.components/Toaster/Toaster.actions'
import { ERROR, SUCCESS } from 'app/App.components/Toaster/Toaster.constants'
import axios from 'axios'
import { LoginInputs, LoginOutputs } from 'shared/user/Login'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_RESULT = 'LOGIN_RESULT'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export type Login = {
  navigate: any
  username: string
  hashedPassword: string
}

export const login =
  ({ navigate, username, hashedPassword }: Login) =>
  async (dispatch: any) => {
    dispatch({
      type: LOGIN_REQUEST,
    })

    try {
      const loginInputs: LoginInputs = {
        username,
        hashedPassword,
      }

      const loginOutputs: LoginOutputs = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/user/login`, loginInputs)).data
      if (!loginOutputs.jwt) throw new Error('Login Error')

      dispatch(showToaster(SUCCESS, 'Login success', 'Welcome to the boilerplate'))

      dispatch({
        type: LOGIN_RESULT,
        jwt: loginOutputs.jwt,
        publicUser: loginOutputs.publicUser,
      })

      navigate('/')
    } catch (error: any) {
      console.error(error)
      dispatch(showToaster(ERROR, 'Error', error?.response?.data?.error || 'Error, please contact support'))
      dispatch({
        type: LOGIN_ERROR,
      })
    }
  }

export const LOGOUT = 'LOGOUT'
export const logout = () => (dispatch: any) => {
  dispatch({
    type: LOGOUT,
  })
}
