import { EmptyPageStyled } from './EmptyPage.style'

export const EmptyPageView = () => {
  return (
    <EmptyPageStyled>
      <h1>Empty Page</h1>
      <pre>Use this as a model for your new pages</pre>
    </EmptyPageStyled>
  )
}
