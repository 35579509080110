import md5 from 'md5'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { State } from '../../reducers'
import { signUp } from './SignUp.actions'
import { SignUpView } from './SignUp.view'

export type SignUpCallbackInputs = {
  username: string
  password: string
}

export const SignUp = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector((state: State) => state.loading)

  const signUpCallback = async (signUpCallbackInputs: SignUpCallbackInputs) => {
    const username = signUpCallbackInputs.username
    const hashedPassword = md5(signUpCallbackInputs.password)
    dispatch(signUp({ navigate, username, hashedPassword }))
  }

  return <SignUpView signUpCallback={signUpCallback} loading={loading} />
}
