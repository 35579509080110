//prettier-ignore
import { Button } from 'app/App.components/Button/Button.view'
import { Input } from 'app/App.components/Input/Input.view'
import { FormInputs, getErrorMessage, getInputStatus, updateFormFromBlur, updateFormFromChange, updateFormFromSubmit } from 'helpers/form'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { SignUpInputs } from 'shared/user/SignUp'

import { SignUpCallbackInputs } from './SignUp.controller'
import { SignUpMeta } from './SignUp.meta'
import { SignUpFooter, SignUpForm, SignUpStyled, SignUpTitle, SignUpWrapper } from './SignUp.style'

type SignUpViewProps = {
  signUpCallback: (values: SignUpCallbackInputs) => void
  loading: boolean
}

export const SignUpView = ({ signUpCallback, loading }: SignUpViewProps) => {
  const [form, setForm] = useState<FormInputs>({
    username: { value: undefined },
    password: { value: undefined },
    confirmPassword: { value: undefined },
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = updateFormFromChange(e, form, SignUpInputs)
    setForm(updatedForm)
  }

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = updateFormFromBlur(e, form)
    if (e.target.name === 'password' && !e.target.value) {
      updatedForm.password.error = 'Please fill in password'
    }
    if (e.target.name === 'confirmPassword' && updatedForm.password.value !== updatedForm.confirmPassword.value) {
      updatedForm.confirmPassword.error = 'Not identical to password'
    }
    setForm(updatedForm)
  }

  const handleSubmit = (event: SyntheticEvent) => {
    const updatedForm = updateFormFromSubmit(event, form, SignUpInputs)

    if (!updatedForm.username.error && !updatedForm.password.error && !updatedForm.confirmPassword.error)
      signUpCallback({
        username: updatedForm.username.value as string,
        password: updatedForm.password.value as string,
      })
    else setForm(updatedForm)
  }

  return (
    <SignUpStyled>
      <SignUpMeta />
      <SignUpWrapper>
        <SignUpTitle>
          <h1>SIGN UP</h1>
        </SignUpTitle>
        <SignUpForm>
          <form onSubmit={handleSubmit}>
            <Input
              icon="user"
              name="username"
              placeholder="Username"
              type="text"
              onChange={handleChange}
              value={form.username.value}
              onBlur={handleBlur}
              inputStatus={getInputStatus(form.username)}
              errorMessage={getErrorMessage(form.username)}
            />
            <Input
              icon="password"
              name="password"
              placeholder="Password"
              type="password"
              onChange={handleChange}
              value={form.password.value}
              onBlur={handleBlur}
              inputStatus={getInputStatus(form.password)}
              errorMessage={getErrorMessage(form.password)}
            />
            <Input
              icon="password"
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
              onChange={handleChange}
              value={form.confirmPassword.value}
              onBlur={handleBlur}
              inputStatus={getInputStatus(form.confirmPassword)}
              errorMessage={getErrorMessage(form.confirmPassword)}
            />
            <SignUpFooter>
              <div>
                <Link to="/login">
                  Or <u>login now</u>!
                </Link>
              </div>
              <Button type="submit" icon="arrow" loading={loading}>
                SIGN UP
              </Button>
            </SignUpFooter>
          </form>
        </SignUpForm>
      </SignUpWrapper>
    </SignUpStyled>
  )
}

SignUpView.propTypes = {
  signUpCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

SignUpView.defaultProps = {
  loading: false,
}
