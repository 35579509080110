import { LOGIN_ERROR, LOGIN_RESULT, LOGOUT } from 'pages/Login/Login.actions'
import { SIGN_UP_ERROR, SIGN_UP_RESULT } from 'pages/SignUp/SignUp.actions'
import { Jwt } from 'shared/user/Jwt'
import { PublicUser } from 'shared/user/PublicUser'

export interface AuthState {
  jwt?: Jwt
  user?: PublicUser
}

const authDefaultState: AuthState = {
  jwt: undefined,
  user: undefined,
}

export function auth(state = authDefaultState, action: any): AuthState {
  switch (action.type) {
    case SIGN_UP_RESULT: {
      return {
        jwt: action.jwt,
        user: action.publicUser,
      }
    }
    case SIGN_UP_ERROR: {
      return {
        jwt: undefined,
        user: undefined,
      }
    }
    case LOGOUT:
      return {
        jwt: undefined,
        user: undefined,
      }
    case LOGIN_RESULT: {
      return {
        jwt: action.jwt,
        user: action.publicUser,
      }
    }
    case LOGIN_ERROR: {
      return {
        jwt: undefined,
        user: undefined,
      }
    }
    default:
      return state
  }
}
