import { AuthState } from 'reducers/auth'
import { UserStyled } from './User.style'

type UserViewProps = {
  auth: AuthState
}

export const UserView = ({ auth }: UserViewProps) => {
  return (
    <UserStyled>
      <h1>Profile page</h1>
      {auth.user ? (
        <div>
          <pre>Username: {auth.user.username}</pre>
          <pre>JWT: {auth.jwt}</pre>
        </div>
      ) : (
        <pre>Not logged in</pre>
      )}
    </UserStyled>
  )
}
