//prettier-ignore
import { Button } from 'app/App.components/Button/Button.view'
import { Input } from 'app/App.components/Input/Input.view'
import { FormInputs, getErrorMessage, getInputStatus, updateFormFromBlur, updateFormFromChange, updateFormFromSubmit } from 'helpers/form'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { LoginInputs } from 'shared/user/Login'

import { LoginCallbackInputs } from './Login.controller'
import { LoginMeta } from './Login.meta'
import { LoginFooter, LoginForm, LoginStyled, LoginTitle, LoginWrapper } from './Login.style'

type LoginViewProps = {
  loginCallback: (values: LoginCallbackInputs) => void
  loading: boolean
}

export const LoginView = ({ loginCallback, loading }: LoginViewProps) => {
  const [form, setForm] = useState<FormInputs>({
    username: { value: undefined },
    password: { value: undefined },
  })

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = updateFormFromChange(e, form, LoginInputs)
    setForm(updatedForm)
  }

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedForm = updateFormFromBlur(e, form)
    if (e.target.name === 'password' && !e.target.value) {
      updatedForm.password.error = 'Please fill in password'
    }
    setForm(updatedForm)
  }

  const handleSubmit = (event: SyntheticEvent) => {
    const updatedForm = updateFormFromSubmit(event, form, LoginInputs)

    if (!updatedForm.username.error && !updatedForm.password.error)
      loginCallback({
        username: updatedForm.username.value as string,
        password: updatedForm.password.value as string,
      })
    else setForm(updatedForm)
  }

  return (
    <LoginStyled>
      <LoginMeta />
      <LoginWrapper>
        <LoginTitle>
          <h1>LOGIN</h1>
        </LoginTitle>
        <LoginForm>
          <form onSubmit={handleSubmit}>
            <Input
              icon="user"
              name="username"
              placeholder="Username"
              type="text"
              onChange={handleChange}
              value={form.username.value}
              onBlur={handleBlur}
              inputStatus={getInputStatus(form.username)}
              errorMessage={getErrorMessage(form.username)}
            />
            <Input
              icon="password"
              name="password"
              placeholder="Password"
              type="password"
              onChange={handleChange}
              value={form.password.value}
              onBlur={handleBlur}
              inputStatus={getInputStatus(form.password)}
              errorMessage={getErrorMessage(form.password)}
            />
            <LoginFooter>
              <div>
                <Link to="/sign-up">
                  Or <u>sign up now</u>!
                </Link>
              </div>
              <Button type="submit" icon="arrow" loading={loading}>
                LOGIN
              </Button>
            </LoginFooter>
          </form>
        </LoginForm>
      </LoginWrapper>
    </LoginStyled>
  )
}

LoginView.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

LoginView.defaultProps = {
  loading: false,
}
