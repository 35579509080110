import styled from 'styled-components/macro'
import { textColor, primaryColor, backgroundColor, placeholderTextColor } from 'styles'

export const NewCommentStyled = styled.div<{ expanded: boolean }>`
  margin-top: 10px;
  padding: 10px;
  background: ${backgroundColor};
  overflow: hidden;
  height: ${(props) => (props.expanded ? 'initial' : '70px')};

  border: 2px solid rgba(255, 255, 255, 0.05);
  box-sizing: border-box;
  box-shadow: rgb(8 21 45 / 60%) -5px -5px 10px, rgb(5 12 27 / 60%) 5px 5px 10px;
  border-radius: 8px;
  padding: 20px;
`

export const NewCommentTextArea = styled.textarea`
  height: 110px;
  display: inline-block;
  background-color: ${backgroundColor};
  resize: none;
  border: none;
  margin-bottom: 10px;
  width: 100%;
  color: ${textColor};
  font-family: 'Metropolis';

  ::placeholder {
    color: ${placeholderTextColor};
  }
`

export const NewCommentButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 150px)});
  grid-gap: 10px;
`

export const NewCommentImageContainer = styled.div`
  > div {
    position: relative;
    display: inline-block;
  }
`

export const NewCommentImage = styled.img`
  max-width: 100%;
  height: 200px;
  margin: 10px 0;
  display: block;
`

export const NewCommentImageClose = styled.div`
  position: absolute;
  top: 0;
  right: -10px;
  height: 30px;
  width: 30px;
  background: ${primaryColor};
  border-radius: 15px;
  cursor: pointer;

  > svg {
    height: 14px;
    width: 14px;
    margin: 8px;
    stroke-width: 4;
    stroke: ${textColor};
  }
`
