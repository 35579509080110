import { Meta } from 'app/App.components/Meta/Meta.view'
import { Comments } from './Comments/Comments.controller'
import { HomeStyled } from './Home.style'

export const HomeView = () => {
  return (
    <HomeStyled>
      <Meta title="Fullstack Boilerplate" description="Fullstack Boilerplate for Smartchain projects. Get started fast on new projects!" />
      <h1>Fullstack Boilerplate</h1>
      <pre>
        This boilerplate is intended to be used as a basis for development of new full stack projects at Smartchain. More info on{' '}
        <a href="https://github.com/smart-chain-fr/fullstack-boilerplate" target="_blank" rel="noreferrer">
          Github
        </a>
        .
      </pre>
      <Comments />
    </HomeStyled>
  )
}
