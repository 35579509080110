import { Button } from 'app/App.components/Button/Button.view'
import * as PropTypes from 'prop-types'
import * as React from 'react'
import { useState } from 'react'

// prettier-ignore
import { NewCommentButtons, NewCommentStyled, NewCommentTextArea } from './NewComment.style'

type NewCommentViewProps = {
  loading: boolean
  expanded: boolean
  newCommentCallback: (comment: string | undefined) => void
  expandCallback: () => void
}

export const NewCommentView = ({ loading, expanded, newCommentCallback, expandCallback }: NewCommentViewProps) => {
  const [comment, setComment] = useState<string | undefined>(undefined)

  return (
    <NewCommentStyled expanded={expanded} onClick={() => expandCallback()}>
      <NewCommentTextArea placeholder="Your comment..." onChange={(e) => setComment(e.target.value)} value={comment} />

      <NewCommentButtons>
        <Button icon="arrow" loading={loading} clickCallback={() => newCommentCallback(comment)}>
          Submit
        </Button>
      </NewCommentButtons>
    </NewCommentStyled>
  )
}

NewCommentView.propTypes = {
  loading: PropTypes.bool,
  expanded: PropTypes.bool,
  newCommentCallback: PropTypes.func.isRequired,
  expandCallback: PropTypes.func.isRequired,
}

NewCommentView.defaultProps = {
  loading: false,
  expanded: false,
}
