import styled from 'styled-components/macro'
import { CardPage } from 'styles'

export const LoginStyled = styled(CardPage)``

export const LoginWrapper = styled.div`
  width: 360px;
`

export const LoginTitle = styled.div`
  margin-bottom: 20px;
`

export const LoginFooter = styled.div`
  text-align: left;
  display: grid;
  grid-template-columns: auto 150px;
  grid-gap: 10px;
  line-height: 20px;
  margin-top: 20px;

  > div {
    line-height: 50px;
  }
`

export const LoginForm = styled.div`
  input {
    margin-bottom: 10px;
  }
`
