import { arrayToObjects } from 'helpers/arrayToObjects'
import { GET_COMMENTS_REQUEST, GET_COMMENTS_RESULT } from 'pages/Home/Comments/Comments.actions'
import { NEW_COMMENT_RESULT } from 'pages/Home/NewComment/NewComment.actions'
import { CommentWithPublicUser } from 'shared/comment/CommentWithPublicUser'

export interface CommentsState {
  newCommentsDoneIds: number[]
  commentsWithPublicUsers: Record<string, CommentWithPublicUser>
  page: number
  hasMore: boolean
}

const commentsDefaultState: CommentsState = {
  newCommentsDoneIds: [],
  commentsWithPublicUsers: {},
  page: 0,
  hasMore: false,
}

export function comments(state = commentsDefaultState, action: any): CommentsState {
  switch (action.type) {
    case GET_COMMENTS_REQUEST: {
      return {
        ...state,
        page: action?.page || 0,
        hasMore: false,
      }
    }
    case GET_COMMENTS_RESULT: {
      return {
        ...state,
        commentsWithPublicUsers:
          action.page === 0
            ? arrayToObjects(action.commentsWithPublicUsers, 'comment', '_id')
            : {
                ...state.commentsWithPublicUsers,
                ...arrayToObjects(action.commentsWithPublicUsers, 'comment', '_id'),
              },
        page: action.page,
        hasMore: action.commentsWithPublicUsers?.length >= 20,
      }
    }
    case NEW_COMMENT_RESULT: {
      return {
        ...state,
        newCommentsDoneIds: [action.doneId, ...state.newCommentsDoneIds],
        commentsWithPublicUsers: {
          [action.commentWithPublicUser.comment._id as unknown as string]: action.commentWithPublicUser,
          ...state.commentsWithPublicUsers,
        },
      }
    }
    default:
      return state
  }
}
