import { Link } from 'react-router-dom'
import { PublicUser } from 'shared/user/PublicUser'

import { Button } from '../Button/Button.view'
import { Hamburger } from './Hamburger/Hamburger.view'
// prettier-ignore
import { MenuBar, MenuLogo, MenuCenter, MenuLeft, MenuStyled } from './Menu.style'

type MenuViewProps = {
  showing: boolean
  setShowing: (status: boolean) => void
  user?: PublicUser
  logoutCallback: () => void
}

export const MenuView = ({ showing, setShowing, user, logoutCallback }: MenuViewProps) => {
  return (
    <MenuStyled>
      <MenuBar showing={showing}>
        <Link to="/" onClick={() => setShowing(false)}>
          <MenuLogo alt="Boilerplate" src="/logo.svg" />
        </Link>
        <div />
        <MenuCenter>
          <Link to="/" onClick={() => setShowing(false)}>
            Home
          </Link>
          <Link to="/empty-page" onClick={() => setShowing(false)}>
            Empty Page
          </Link>
          <a href="https://github.com/smart-chain-fr/fullstack-boilerplate" target="_blank" rel="noreferrer">
            Github
          </a>
        </MenuCenter>
        <div />
        {user ? (
          <MenuLeft>
            <Link to="/user" onClick={() => setShowing(false)}>
              {user.username}
            </Link>
            <Button appearance="primary" icon="arrow" clickCallback={() => logoutCallback()}>
              Logout
            </Button>
          </MenuLeft>
        ) : (
          <MenuLeft>
            <Link to="/sign-up" onClick={() => setShowing(false)}>
              Sign Up
            </Link>
            <Link to="/login" onClick={() => setShowing(false)}>
              <Button appearance="primary" icon="arrow">
                Login
              </Button>
            </Link>
          </MenuLeft>
        )}

        <Hamburger showing={showing} setShowing={() => setShowing(!showing)} />
      </MenuBar>
    </MenuStyled>
  )
}
