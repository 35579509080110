import md5 from 'md5'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import { State } from '../../reducers'
import { login } from './Login.actions'
import { LoginView } from './Login.view'

export type LoginCallbackInputs = {
  username: string
  password: string
}

export const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loading = useSelector((state: State) => state.loading)

  const loginCallback = async (loginCallbackInputs: LoginCallbackInputs) => {
    const username = loginCallbackInputs.username
    const hashedPassword = md5(loginCallbackInputs.password)
    dispatch(login({ navigate, username, hashedPassword }))
  }

  return <LoginView loginCallback={loginCallback} loading={loading} />
}
